@import '../../scss/colors.module.scss';
@import '../../scss/breakpoints.module.scss';

.card {
  display: flex;
  flex: 1;
  max-width: $BREAKPOINT_EXTRA_LARGE;
  margin: 0 auto;
  padding: 3rem;
  background-color: $COLOR_GREY_100;
  box-shadow: rgb(0, 0, 0, 0.3) 0px 6px 12px;

  > * + * {
    margin-left: 2rem;
  }

  @media (max-width: $BREAKPOINT_SMALL) {
    flex-direction: column;
    padding: 1rem;

    > * + * {
      margin: 2rem 0rem 0rem;
    }
  }
}

.leftpane {
  margin: auto;
  background: none;
  border: none;
  cursor: pointer;
}

.rightpane {
  display: grid;
  grid-auto-flow: row;
  gap: 0.25rem;
  min-width: 22rem;

  @media (max-width: $BREAKPOINT_SMALL) {
    min-width: 0;
  }
}
