@import '../../scss/colors.module.scss';

.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  max-width: 100vw;
  min-height: 4rem;
  padding: 0px 10px 10px;
  background: $COLOR_PRIMARY;
}

.logoContainer {
  width: 100vw;
  text-align: center;

  .logo {
    margin: auto 0;
    height: 34px;
  }
}

.selector {
  margin: auto;
  min-width: 80vw;
  max-width: 80vw;
}

.editButtons {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.mainMenu {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

@media (min-width: 280px) {
  .editButtons {
    margin: auto;
  }
  .mainMenu {
    margin: auto;
  }
}

@media (min-width: 356px) {
  .selector {
    margin: 0px 10px;
    min-width: 38vw;
    max-width: 38vw;
  }
  .editButtons {
    margin: 0 10px;
  }
  .mainMenu {
    margin: 0 10px;
  }
}

@media (min-width: 650px) {
  .selector {
    min-width: 28vw;
    max-width: 28vw;
  }
}

@media (min-width: 868px) {
  .selector {
    min-width: 200px;
    max-width: 200px;
  }
}

@media (min-width: 980px) {
  .mainMenu {
    margin-left: auto;
  }
}

@media (min-width: 1115px) {
  .logoContainer {
    width: auto;
  }

  .header {
    padding-bottom: 0px;
  }
}
