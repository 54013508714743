.container {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;
}

.button {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0.25rem;
  font-size: 1rem;
}
