@import './typography.scss';
@import './colors.module.scss';

.linkButton {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 14px;
  color: $COLOR_WHITE;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.boxShadow-default {
  box-shadow: rgb(0, 0, 0, 0.3) 0px 6px 12px;
}

.boxShadow-hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 12px, rgba(0, 0, 0, 0.18) 0px 10px 16px;
}

.officeCard {
  @extend .boxShadow-default;
  max-width: 255px;
  min-width: 255px;
  max-height: 210px;
  min-height: 210px;
  border-radius: 4px;
  padding: 0 8px;
  background-color: darken($COLOR_PRIMARY, 12%);
  text-align: left;
  color: $COLOR_WHITE;

  &:hover {
    @extend .boxShadow-hover;
  }
}
