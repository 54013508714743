@import '../../../scss/breakpoints.module.scss';
@import '../../../scss/colors.module.scss';

.card {
  padding: 3rem;
  background-color: $COLOR_GREY_100;
  box-shadow: rgb(0, 0, 0, 0.3) 0px 6px 12px;

  @media (max-width: $BREAKPOINT_SMALL) {
    padding: 1rem;
  }
}
