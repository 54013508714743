@import './typography.scss';
@import './colors.module.scss';

html {
  font-family: 'Roboto', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  font-size: 14px;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: lightgray;
}

::-webkit-scrollbar-thumb:hover {
  background: darken(lightgray, 7%);
}
