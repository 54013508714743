@import '../../scss/shared.scss';
@import '../../scss/colors.module.scss';
@import '../../scss/breakpoints.module.scss';

.container {
  display: flex;
  flex-flow: column;
  flex: 1;
  background-color: $COLOR_SECONDARY;
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 4rem;
  padding: 0rem 10px;
  background-color: $COLOR_PRIMARY;
}

.content {
  flex: 1;
  padding: 3rem 3rem 5.715rem;

  @media (max-width: $BREAKPOINT_SMALL) {
    padding: 1rem 1rem 5.715rem;
  }
}
