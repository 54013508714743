@import '../../../scss/colors.module.scss';
@import '../../../scss/breakpoints.module.scss';

.container {
  display: flex;
  align-items: center;
  height: 4.5rem;
}

.inputContainer {
  flex: 1;
}

.input {
  width: 100%;
  padding: 1.25rem 0.25rem 0.125rem;
  border: none;
  border-bottom: 1px solid $COLOR_BLACK;
  background-color: $COLOR_GREY_100;
  font-size: 1.5rem;
  font-weight: 500;
}

.error {
  height: 1.125rem;
  margin: 0rem 0.25rem;
  color: $COLOR_SECONDARY;
}

.userName {
  margin: 1.25rem 0.25rem;
  font-size: 1.5rem;
  font-weight: 500;
}
