@import '../../../scss/colors.module.scss';

.item {
  display: flex;
  align-items: center;
  padding: 0.75rem 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0rem 0.1875rem 0.5rem;
  background-color: $COLOR_GREY_100;
  min-width: 20vw;
}

.name {
  flex: 1;
  padding: 0rem 0.75rem;
  font-weight: 500;
}

.placeButton {
  color: $COLOR_BLACK !important;
}
