@import '../../../scss/colors.module.scss';

.person {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: $COLOR_GREY_300;
  color: blue;

  &.moveEnabled {
    cursor: grab;
  }
}
