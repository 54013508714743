$BREAKPOINT_SMALL: 576px;
$BREAKPOINT_MEDIUM: 768px;
$BREAKPOINT_LARGE: 992px;
$BREAKPOINT_EXTRA_LARGE: 1200px;

:export {
  small: $BREAKPOINT_SMALL;
  medium: $BREAKPOINT_MEDIUM;
  large: $BREAKPOINT_LARGE;
  extraLarge: $BREAKPOINT_EXTRA_LARGE;
}
