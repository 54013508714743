@import '../scss/breakpoints.module.scss';
@import '../scss/colors.module.scss';

.container {
  display: flex;
  flex-flow: column;
  flex: 1;
  background-color: $COLOR_SECONDARY;
}

.card {
  max-width: $BREAKPOINT_EXTRA_LARGE;
  margin: 1.5rem;
  padding: 3rem;
  background-color: $COLOR_GREY_100;
  box-shadow: rgb(0, 0, 0, 0.3) 0px 6px 12px;
}
