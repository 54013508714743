@import '../../../scss/colors.module.scss';
@import '../../../scss/breakpoints.module.scss';
@import '../../../scss/global.scss';

.buttonContainer {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;

  > button:disabled {
    visibility: hidden;
  }

  @media (max-width: $BREAKPOINT_SMALL) {
    padding-bottom: 0.75rem;
  }
}

/*
    Mui drawer takes whole viewport height by default
    Margin top adjusts its height based on the current size of the navbar/header
  */
.drawer {
  padding: 1rem;
  margin-top: 56px;

  @media (max-width: $BREAKPOINT_SMALL) {
    width: calc(100% - 2rem);
  }

  @media (max-width: 1114px) {
    margin-top: 97px;
  }

  @media (max-width: 971px) {
    margin-top: 140.5px;
  }

  @media (max-width: 649px) {
    margin-top: 147px;
  }

  @media (max-width: 496px) {
    margin-top: 190.5px;
  }

  @media (max-width: 355px) {
    margin-top: 240.5px;
  }

  @media (max-width: 275px) {
    margin-top: 270.5px;
  }
}

.hideButton {
  justify-content: flex-start !important;
  padding: 0.5rem 0rem !important;
  color: $COLOR_BLACK !important;

  > svg {
    margin-left: 0.25rem;
  }
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.375rem;
}
