.container {
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  touch-action: none;
}
