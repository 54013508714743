@import '../scss/colors.module.scss';
@import '../scss/breakpoints.module.scss';

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 1rem 3.125rem 3.125rem;

  @media (max-width: $BREAKPOINT_SMALL) {
    padding: 0.75rem;
  }
}

.dragDropBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
}
