$COLOR_PRIMARY: #f04e30;
$COLOR_SECONDARY: #d75539;
$COLOR_WHITE: #ffffff;
$COLOR_BLACK: #443938; // this is pretty weird black??

// grey colors

$COLOR_GREY_100: #f6f6f6;
$COLOR_GREY_200: #f0f0f0;
$COLOR_GREY_300: #e2e2e2;
$COLOR_GREY_400: #c0c0c0;
$COLOR_GREY_500: #a1a1a1;
$COLOR_GREY_600: #787878;
$COLOR_GREY_700: #636363;
$COLOR_GREY_800: #444444;
$COLOR_GREY_900: #232323;

:export {
  primary: $COLOR_PRIMARY;
  white: $COLOR_WHITE;
  black: $COLOR_BLACK;
  grey_100: $COLOR_GREY_100;
  grey_200: $COLOR_GREY_200;
  grey_300: $COLOR_GREY_300;
  grey_400: $COLOR_GREY_400;
  grey_500: $COLOR_GREY_500;
  grey_600: $COLOR_GREY_600;
  grey_700: $COLOR_GREY_700;
  grey_800: $COLOR_GREY_800;
  grey_900: $COLOR_GREY_900;
}
