@import '../../../scss/colors.module.scss';
@import '../../../scss/breakpoints.module.scss';

.card {
  padding: 2rem;
  background-color: $COLOR_GREY_100;
  margin: auto;
  box-shadow: rgb(0, 0, 0, 0.3) 0px 6px 12px;
  width: 500px;

  @media (max-width: $BREAKPOINT_SMALL) {
    margin: auto 1rem;
    width: 100%;
  }
}

.close {
  display: flex;
  justify-content: flex-end;
}

.avatar {
  width: 100px !important;
  height: 100px !important;
  margin: 0 auto !important;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
